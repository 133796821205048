<template>
	<div class="container" v-if="demoProgressLoaded">
		<div class="progress">
			<el-progress :color="progressColor" :status="progressStatus" :width="150" type="circle"
				:percentage="demoProgress.percentage">
			</el-progress>
		</div>
		<div class="steps">
			<div class=" demo-steps">
				<p v-if="demoProgress.percentage == 100">{{$t('account.demo.header-success')}}</p>
				<p v-else>{{$t('account.demo.header')}}</p>
				<div class="steps-list">
					<div class="step" v-for="(details, step) in demoProgress.progress" :key="step">
						<div class="checkbox">
							<template v-if="details.status == 1">
								<svg-icon icon-class="check" class="color-green"></svg-icon>
							</template>
							<template v-else>
								<el-checkbox disabled></el-checkbox>
							</template>
						</div>
						<div :class="['step-name', stepDescClass(details.status)]">
							<template v-if="details.status == 1">
								{{$t(`account.demo.step-description-${step}`)}}
							</template>
							<template v-else>
								<a :href="details.link"
									class="cel-link">{{$t(`account.demo.step-description-${step}`)}}</a>
							</template>
						</div>
					</div>
				</div>
				<p class="tip" v-html="noticeText()"></p>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		sync,
		get,
		call
	} from 'vuex-pathify';

	export default {
		data: () => ({}),
		computed: {
			demoProgress: get('account/demoProgress'),
			demoProgressLoaded: get('account/demoProgressLoaded'),
			demoDaysReaming: get('account/reamingDemoDays'),
			totalSteps() {
				return Object.keys(this.demoProgress.progress).length;
			},
			progressColor() {
				return this.demoProgress.percentage == 100 ? '#67c23a' : 'rgb(255, 103, 35)';
			},
			progressStatus() {
				return this.demoProgress.percentage == 100 ? 'success' : null;
			}
		},
		methods: {
			getAffiliateDemoProgress: call('account/GetDemoProgress'),
			getDemoDaysReaming: call('account/GetReamingDemoDays'),
			format() {
				return `${this.demoProgress.stepsDone} / ${this.totalSteps}`;
			},
			stepDescClass(state) {
				return state == 1 ? 'success' : '';
			},
			noticeText() {
				const ReamingDays = this.demoDaysReaming;
				return this.$t('account.demo.products-notice') + " " + "<b class='color-cel'>" + (ReamingDays == 1 ? this.$t('account.demo.days-reaming_pl').replace('%days%',ReamingDays.zile) : this.$t('account.demo.days-reaming_sg')) + "</b>";
			}
		},
		async mounted() {
			try {
				await this.getAffiliateDemoProgress();
			} catch (err) {
				this.$error(err);
				this.$reportToSentry(e, {
					extra: {
						fn: 'getAffiliateDemoProgress'
					}
				});
			}

			try {
				await this.getDemoDaysReaming();
			} catch (err) {
				this.$error(err);
				this.$reportToSentry(e, {
					extra: {
						fn: 'getDemoDaysReaming'
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	.container {
		display: flex;
		flex-direction: row;
		color: #606266;

		justify-content: start;
		align-items: center;

		.progress {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: 10%;
			margin-right: 10px;
		}

		.color-green {
			color: #67c23a;
		}

		// p {
		// 	font-size: 14px;
		// }

		.steps-list {
			font-size: 14px;

			.step {
				display: flex;
				flex-direction: row;
				justify-content: start;
				align-content: center;

				&:not(:last-child) {
					padding-bottom: 5px;
				}

				.checkbox {
					padding: 0 10px;
				}
			}

			.step-name.success {
				text-decoration: line-through;
				color: #67c23a;
			}
		}
	}

	@media screen and (max-width: 500px) {
		.container {
			display: flex;
			flex-direction: column;

			justify-content: center;
			align-content: center;

			.progress {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: 100%;
				text-align: center;
			}

			p {
				font-size: 14px;
			}

			.steps-list {
				font-size: 13px;

				.step:not(:last-child) {
					padding-bottom: 10px;
				}
			}
		}
	}

	.tip {
		font-size: 13px;
		color: #909399;
	}

	.cel-link:hover {
		color: rgb(255, 103, 35);
	}

	::v-deep .el-icon-check {
		font-size: 3.5em;
	}

	.color-cel {
		color: #ff601d;
	}
</style>